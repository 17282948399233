// used in action_template.html.erb for reception and removal path
import ApplicationController from './application_controller'
import { localeFromURL } from '../components/utilities'
import SlimSelect from 'slim-select'
import 'slim-select/styles'

import QrScanner from 'qr-scanner'

// eslint-disable-next-line import/no-webpack-loader-syntax
import QrScannerWorkerPath from '!!file-loader!../../../node_modules/qr-scanner/qr-scanner-worker.min.js'

QrScanner.WORKER_PATH = QrScannerWorkerPath

export default class extends ApplicationController {
  static targets = [
    'itemCheckbox', 'status', 'comment', 'actionTag', 'incidentTag',
    'video', 'close', 'openButton', 'openedSection', 'submitToUndisabled'
  ]

  connect () {
    if (document.getElementById('action_tags')) {
      this.actionSelect = new SlimSelect({ select: this.actionTagTarget })
    }
    if (document.getElementById('incident_tags')) {
      this.statusSelect = new SlimSelect({ select: this.incidentTagTarget })
    }

    if (document.getElementById('scan-panel')) {
      this.qrScanner = new QrScanner(this.videoTarget, result => this.scannedItem(result))
      this.qrScanner.start()
    }

    if (this.element.dataset.selectedItem) {
      this.activateCheckbox()
    }

    this.orderItemsIds = this.element.dataset.orderItemsIds.split(',')
    this.orderItemsSelected = null
  }

  toggleCheckbox (event) {
    let item = event.currentTarget
    let checkBox = item.querySelector('input[type=checkbox]')

    checkBox.checked = !checkBox.checked
    if (checkBox.checked) {
      item.classList.remove('btn-primary')
      item.classList.add('btn-success')
    } else {
      item.classList.add('btn-primary')
      item.classList.remove('btn-success')
    }

    this.orderItemsSelected = this.itemCheckboxTargets.filter(element => element.checked)
      .map(element => element.id.split('item_')[1])
  }

  activateCheckbox() {
    this.itemCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = true
      let item = checkbox.closest('.item')
      item.classList.remove('btn-primary')
      item.classList.add('btn-success')
    })
  }

  notify (notification = { type: 'error', text: 'Something went wrong' }) {
    this.utility('notification').notify(notification)
  }

  // SCANNER METHOD ______________________________________________________________________

  toggleScanPanel () {
    this.qrScanner ? this.hideScanPanel() : this.showScanPanel()
  }

  showScanPanel () {
    this.qrScanner = new QrScanner(this.videoTarget, result => this.scannedItem(result))
    this.qrScanner.start()
    this.openButtonTarget.style.display = 'none'
    this.openedSectionTarget.style.display = 'block'
  }

  hideScanPanel () {
    this.qrScanner.stop()
    this.qrScanner = null
    this.lastResult = null
    this.openButtonTarget.style.display = 'block'
    this.openedSectionTarget.style.display = 'none'
  }

  scannedItem (result) {
    if (this.lastResult === result) {
      return
    }
    this.lastResult = result

    try {
      const parsedResult = JSON.parse(result)
      const itemId = Number(parsedResult.id).toString()

      if (parsedResult.type !== 'item') {
        this.errorFeedback()
        return
      }
      if (this.orderItemsIds.indexOf(itemId) === -1) {
        this.errorFeedback()
        return
      }

      if (this.orderItemsSelected.indexOf(itemId) !== -1) {
        this.successFeedback()
        return
      }

      this.toggleCheckbox(null, itemId)
      this.successFeedback()
    } catch (e) {
      this.errorFeedback()
    }
  }

  successFeedback () {
    this.beep('success')
    this.flash('green')
  }

  errorFeedback () {
    this.beep('error')
    this.flash('red')
  }

  flash (color) {
    const flashElement = document.getElementById('flash')
    const colorFlashClass = color + '-flash'

    flashElement.classList.add(colorFlashClass)
    flashElement.style.display = 'block'
    setTimeout(() => {
      flashElement.style.display = 'none'
      flashElement.classList.remove(colorFlashClass)
    }, 2000)
  }

  beep (kind) {
    const scanAudioFeedback = document.querySelector(`#scan-panel audio.${kind}`)

    scanAudioFeedback.load()
    setTimeout(() => {
      scanAudioFeedback.play()
    }, 50)
  }

  // SUBMISSION METHOD ____________________________________________________________________

  validateSubmission (event) {
    event.preventDefault()
    event.stopImmediatePropagation()

    const status = this.element.dataset.status
    const orderAction = this.element.dataset.orderAction

    const itemsCount = parseInt(this.element.dataset.orderItemCount)
    const submitStatus = event.currentTarget.dataset.status

    // array of files
    this.uploads = this.retrieveUploads()

    switch (submitStatus) {
      case status:
        const isUserAdmin = document.getElementById('is_user_admin_').value
        if (this.itemCheckboxTargets.filter(element => element.checked).length !== itemsCount) {
          return this.notify({
            type: 'primary',
            text: {
              en: 'You need to select all lots in order to validate.',
              fr: 'Vous devez sélectionner tous les lots pour valider.'
            }[localeFromURL()]
          })
        } else if (status === 'collected' && this.uploads.length === 0 && isUserAdmin === 'false') {
          return this.notify({
            type: 'primary',
            text: {
              en: 'You must add at least one image in order to validate.',
              fr: 'Vous devez ajouter au moins une image pour valider.'
            }[localeFromURL()]
          })
        } else {
          // proceed
          this.statusTarget.value = status
          this.submit(event)
        }
        break

      case `${orderAction}_incident`:
        if (this.statusSelect.getSelected().length === 0) {
          return this.notify({
            type: 'primary',
            text: {
              en: 'You need to select at least one category to declare an incident.',
              fr: 'Vous devez sélectionner au moins une catégorie pour déclarer un incident.'
            }[localeFromURL()]
          })
        } else if (this.commentTarget.value === '') {
          return this.notify({
            type: 'primary',
            text: {
              en: 'You need to type a comment to declare an incident.',
              fr: 'Vous devez rédiger un commentaire pour déclarer un incident.'
            }[localeFromURL()]
          })
        }

        this.statusTarget.value = `${orderAction}_incident`
        this.submit(event)

        break
    }
  }

  retrieveUploads () {
    return this.utilities('upload-picture').map(util => util.retrievePictures()).flat()
  }

  async addToFormData (formData, array, requestValue) {
    return this.utility('resize-uploaded-files').addToFormData(formData, array, requestValue)
  }

  async uploadResizedImage (formData) {
    this.uploads.forEach(async (file) => {
      const config = {
        file: file,
        maxSize: 200
      }
      const resizedImage = await this.resizePhotos(config)
      const newImage = await this.blobToFile(resizedImage, file.name)
      formData.append('uploads[]', newImage, file.name)
    })

    return formData
  }

  blobToFile (blob, filename) {
    return this.utility('resize-uploaded-files').blobToFile(blob, filename)
  }

  resizePhotos (settings) {
    return this.utility('resize-uploaded-files').resizePhotos(settings)
  }

  utility(identifier) {
    return this.utilities(identifier)[0]
  }

  utilities(identifier) {
    const elements = document.querySelectorAll(`[data-controller="${identifier}"]`)

    return Array.from(elements)
                .map(el => this.application.getControllerForElementAndIdentifier(el, identifier))
  }

  // AJAX call to Removals#update or Reception#update
  async submit (event) {
    event.currentTarget.disabled = true
    const form = event.currentTarget.closest('form')
    var formData = new FormData(form)

    // uploads
    formData = await this.addToFormData(formData, this.uploads, 'uploads[]')

    Rails.ajax({
      url: form.action,
      type: 'PUT',
      data: formData,
      success: (data) => {
        if (this.element.dataset.roundId) {
          if (this.element.dataset.source) {
            window.location.href = `/${localeFromURL()}/rounds/${this.element.dataset.roundId}?source=${this.element.dataset.source}#${this.element.dataset.pickingAddressId}`
          } else {
            window.location.href = `/${localeFromURL()}/rounds/${this.element.dataset.roundId}#${this.element.dataset.pickingAddressId}`
          }
        } else {
          window.location.reload()
        }
      },
      error: (data) => {
        if (data.message) {
          this.submitToUndisabledTarget.disabled = false
          this.notify({ type: 'error', text: data.message })
        } else {
          this.notify({
            type: 'error',
            text: {
              en: 'Please try later or contact the support team',
              fr: 'Veuillez réessayer ultérieurement ou contacter le support'
            }[localeFromURL()]
          })
        }
      }
    })
  }
}
