import ApplicationController from './application_controller'

export default class extends ApplicationController {
  blobToFile (theBlob, fileName) {
    return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
  }

  // link to code in stackoverflow :
  // https://stackoverflow.com/questions/23945494/use-html5-to-resize-an-image-before-upload

  resizePhotos (settings) {
    var file = settings.file
    var maxSize = settings.maxSize
    var reader = new FileReader()
    var image = new Image()
    var canvas = document.createElement('canvas')
    var dataURItoBlob = function (dataURI) {
      var bytes = dataURI.split(',')[0].indexOf('base64') >= 0
        ? atob(dataURI.split(',')[1])
        : unescape(dataURI.split(',')[1])
      var mime = dataURI.split(',')[0].split(':')[1].split(';')[0]
      var max = bytes.length
      var ia = new Uint8Array(max)
      for (var i = 0; i < max; i++) { ia[i] = bytes.charCodeAt(i) }
      return new Blob([ia], { type: mime })
    }
    var resize = function () {
      var width = image.width
      var height = image.height
      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width
          width = maxSize
        }
      } else {
        if (height > maxSize) {
          width *= maxSize / height
          height = maxSize
        }
      }
      canvas.width = width
      canvas.height = height
      canvas.getContext('2d').drawImage(image, 0, 0, width, height)
      var dataUrl = canvas.toDataURL('image/jpeg')
      return dataURItoBlob(dataUrl)
    }

    return new Promise(function (ok, no) {
      if (!file.type.match(/image.*/)) {
        no(new Error('Not an image'))
        return
      }
      reader.onload = function (readerEvent) {
        image.onload = function () { return ok(resize()) }
        image.src = readerEvent.target.result
      }
      reader.readAsDataURL(file)
    })
  }

  async addToFormData (formData, array, requestValue) {
    for (let i = 0; i < array.length; i++) {
      var config = {
        file: array[i],
        maxSize: 500
      }
      if (config.file.size >= 3e6) {
        var resizedImage = await this.resizePhotos(config)
        var newImage = this.blobToFile(resizedImage, array[i].name)
        formData.append(requestValue, newImage, newImage.name)
      } else {
        formData.append(requestValue, config.file, config.file.name)
      }
    }

    return formData
  }
}
