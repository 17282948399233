import ApplicationController from './application_controller'
import SlimSelect from 'slim-select'
import 'slim-select/styles'

export default class extends ApplicationController {
  // This controller refresh the innerHTML of a specified 'view' target by making a call API to the backend
  // The action is triggerd by any 'input' target
  // Convention for building the url:
  // - Pass the base url value in the 'url' value attribute (i.e data-form-part-refresh-url-value="<%= business_units_url %>")
  // - For any url params retrieved from a target, add a data attribute to the target element with the name of the params: (i.e: 'url-param-name': 'country_code')
  // - For any url params retrieved from a value, set it in the Object value : (i.e: data-form-part-refresh-url-params-value="<%= { order_type: "Order", order_id: params[:id] }.to_json %>")

  static targets = ['input', 'view']

  static values = {
    url: String,
    urlParams: Object
  }

  connect() {
    new SlimSelect({ select: this.viewTarget })
  }

  refreshView() {
    fetch(this.buildUrl(), {
      method: 'GET',
      headers: { Accept: 'text/html' }
    })
      .then(response => response.text())
      .then(html => { this.viewTarget.innerHTML = html })
  }

  buildUrl() {
    let params = new Map()

    this.inputTargets.forEach(target =>
      params.set(target.getAttribute('data-url-param-name'), target.value)
    )

    const url = new URL(
      this.urlValue + '?' + new URLSearchParams(params)
    )

    Object.entries(this.urlParamsValue).forEach(([key, value]) => {
      url.searchParams.set(key, value)
    })

    return url
  }
}
