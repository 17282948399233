import ApplicationController from './application_controller'

export default class SetDestroyValueController extends ApplicationController {
    static targets = ['destroyHiddenField', 'elementToHide'];

    setValueToTrue(event) {
        event.preventDefault();
        this.destroyHiddenFieldTarget.value = 'true';
        this.element.style.display = 'none';
    }
}
