import ApplicationController from './application_controller'
import { localeFromURL } from '../components/utilities'

export default class extends ApplicationController {
  static targets = ['timeInput']

  togglePackingNode(event) {
    const packingId = event.currentTarget.dataset.packingId

    const selectors = `.js-caret-container[data-packing-id="${packingId}"], [data-packing-node-id="${packingId}"]`
    document.querySelectorAll(selectors).forEach((node) => node.classList.toggle('d-none'))
  }

  blobToFile(theBlob, fileName) {
    const upload = document.querySelector('[data-controller="resize-uploaded-files"]')
    let controller = this.application.getControllerForElementAndIdentifier(upload, 'resize-uploaded-files')
    const blobFile = eval(controller['blobToFile'](theBlob, fileName))

    return blobFile
  }

  resizePhotos(settings) {
    const upload = document.querySelector('[data-controller="resize-uploaded-files"]')
    let controller = this.application.getControllerForElementAndIdentifier(upload, 'resize-uploaded-files')
    const blobFile = eval(controller['resizePhotos'](settings))

    return blobFile
  }

  async addToFormData(formData, array, requestValue) {
    const upload = document.querySelector('[data-controller="resize-uploaded-files"]')
    let controller = this.application.getControllerForElementAndIdentifier(upload, 'resize-uploaded-files')
    const blobFile = await eval(controller['addToFormData'](formData, array, requestValue))

    return blobFile
  }

  async finalizePacking() {
    this.uploads = this.retrieveUploads()
    const url = window.location.origin + `/${localeFromURL()}/orders/${this.element.dataset.orderId}/packing_confirms`
    var formData = new FormData()

    formData.append('order[status]', 'packed')
    formData.append('order[packed_at]', new Date())
    formData.append('order[packing_elapsed_time]', this.timeInputTarget.value)
    formData = await this.addToFormData(formData, this.uploads, 'order[packing_uploads][]')

    Rails.ajax({
      url: url,
      type: 'POST',
      data: formData,
      success: (data) => { window.location.reload() },
      error: (data) => {
        if (data.message) {
          this.notify({ type: 'error', text: data.message })
        } else {
          this.notify({
            type: 'error',
            text: {
              en: 'Please try later or contact the support team',
              fr: 'Veuillez réessayer ultérieurement ou contacter le support'
            }[localeFromURL()]
          })
        }
      }
    })
  }

  notify(notification = { type: 'error', text: 'Something went wrong' }) {
    const notificationController = document.querySelector('[data-controller="notification"]')
    let controller = this.application.getControllerForElementAndIdentifier(notificationController, 'notification')
    eval(controller['notify'](notification))
  }

  retrieveUploads() {
    const uploadPicture = document.querySelector('[data-controller="packing upload-picture delete-image reveal"]')
    let controller = this.application.getControllerForElementAndIdentifier(uploadPicture, 'upload-picture')
    const uploads = eval(controller['retrievePictures']())

    return uploads
  }
}
