import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = { submit: String }

  show (event) {
    if (!window.fetch) {
      return
    }

    event.preventDefault()
    const headers = new Headers({ 'Legacy-X-Modal': 'on' })

    fetch(new Request(this.element.href, { method: 'GET', headers: headers }))
      .then(response => response.text())
      .then(html => {
        $('#modalWrapper').html(html)
        $('#defaultModal').modal('show')
      })
  }

  display (data) {
    const [_target, _status, xhr] = data.detail
    $('#modalWrapper').html(xhr.responseText)
    $('#defaultModal').modal('show')
  }

  addModalHeader (data) {
    const [xhr] = data.detail
    xhr.setRequestHeader('Legacy-X-Modal', 'on')
  }
}
