import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ 'input' ]
  static placeListener;
  static sellerListener;
  static autocomplete;
  static role;
  static sellerSelect
  static sellerBounds = {
    'TPK NEW YORK': {
      lat: 40.8390388,
      lng: -99.2691201,
      radius: 2300000
    },
    defaults: {
      lat: 48.8572661,
      lng: 2.3517033,
      radius: 750000
    }
  }

  inputTargetConnected () {
    this.role = this.data.get('role')

    this.setupListeners()
  }

  disconnect () {
    this.removeListeners()
  }

  reconnect () {
    const controller = this
    return function () {
      controller.removeListeners()
      controller.setupListeners()
    }
  }

  triggerEvent () {
    const controller = this

    return function () {

      const event = new CustomEvent(`place_autocomplete.${controller.role}`,
        { detail: { place: this.getPlace() } }
      )

      document.dispatchEvent(event)
    }
  }

  removeListeners () {
    if (this.hasInputTarget) { window.google.maps.event.clearInstanceListeners(this.inputTarget) }

    if (this.placeListener) { this.placeListener.remove() }
    if (this.sellerSelectListener) { this.sellerSelect.removeEventListener(this.sellerSelectListener) }
  }

  setupListeners () {
    this.autocomplete = new window.google.maps.places.Autocomplete(
      this.inputTarget,
      { types: [this.data.get('type') || 'geocode'] }
    )

    this.autocomplete.setBounds(this.currentSellerBounds())
    this.autocomplete.setFields(['address_component', 'formatted_address', 'geometry'])

    this.placeListener =
      this.autocomplete.addListener('place_changed', this.triggerEvent())

    this.sellerSelect = document.getElementById('order_seller_id')
    if (!this.sellerSelect) { return }
    this.sellerListener =
      this.sellerSelect.addEventListener('change', this.reconnect(), false)
  }

  currentSellerBounds () {
    const sellerBounds = this.constructor.sellerBounds
    const currentSellerName = this.sellerSelect?.selectedOptions[0].text
    const bounds = sellerBounds[currentSellerName] || sellerBounds.defaults

    return new window.google.maps.Circle(
      { center: { lat: bounds.lat, lng: bounds.lng }, radius: bounds.radius }
    ).getBounds()
  }
}
